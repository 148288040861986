const maxPages = 8;
const truncatedPages = 5;

export function pagesToDisplay(currentPageNumber: number, totalPages: number) : number[][]{
    const currentPage = Math.max(currentPageNumber -1,0);
    let finalArray = new Array();
    let firstSection = new Array();
    let middleSection = new Array();
    let lastSection = new Array();

    if (totalPages < maxPages) {
        finalArray.push([...Array.from(Array(totalPages).keys())]);
        return finalArray;
    }

    firstSection.push(0);
    lastSection.push(totalPages-1);

    const sectionLen = truncatedPages;

    for (var page=1; page<totalPages-1; page++) {
        if (page < currentPage && page + sectionLen/2 >= currentPage) {
            middleSection.push(page);
            continue;
        }
        if (page === currentPage) {
            middleSection.push(page);
            continue;
        }
        if (page > currentPage && page - sectionLen/2 <= currentPage) {
            middleSection.push(page);
            continue;
        }
    }
    const middleFirst = firstInArray(middleSection);
    const middleLast = lastInArray(middleSection);
    const lastFirst = totalPages-1

    if (middleLast === lastFirst-1 && lastFirst !== undefined) {
        middleSection.push(...lastSection)
        lastSection.splice(0,1);
    }

    if (0 === middleFirst - 1){
        firstSection.push(...middleSection)
        middleSection.splice(0,middleSection.length);
    }

    finalArray.push(firstSection);
    if (middleSection.length>0) {
        finalArray.push(middleSection);
    }
    if (lastSection.length>0) {
        finalArray.push(lastSection);
    }
    return finalArray;
}

   
function firstInArray(array:any[]) : any | undefined {
    if (array !== undefined) {
        return array[0];
    } else {
        return undefined;
    }
}

function lastInArray(array:any[]) : any | undefined {
        if (array !== undefined) {
        return array[array.length-1];
    } else {
        return undefined;
    }
}
