export const menuItems = {
    API_HEALTH: { url: '/api-health', name: 'API Health' },
    GDP_APIS: { url: '/gdp-apis', name: 'GDP APIs' },
    CLIENT_AUTH: { url: 'client-authorizations', name: 'Client Authorizations' },
    VIEW_CLIENT_AUTH: { url: '/view-client-authorizations', name: 'View Client Authorizations' },
    UPDATE_CLIENT_AUTH: { url: '/update-client-authorizations', name: 'Update Client Authorizations' },
    REVOKE_CLIENT_AUTH: { url: '/revoke-client-authorizations', name: 'Revoke Client Authorizations' },
    HISTORY_LOG: { url: '/history-log', name: 'History Log' },
    AUDIT_LOG: { url: '/audit-log', name: 'Audit Log' },
    USERS: { url: '/users', name: 'Users' },
    MAIN_COMPONENT: { url: '/', name: 'Other Component' },
} as const;