import React, { FC } from 'react';
import './HistoryLog.css';

interface HistoryLogProps {}

const HistoryLog: FC<HistoryLogProps> = () => (
  <div className="HistoryLog">
    HistoryLog Component
  </div>
);

export default HistoryLog;
