import React, { useState, useEffect, useRef } from 'react';
import { FilterRequest, PageRequest } from '../../contracts/User';
import { User } from '../../api/out-gdp-ammi-dashboard-backend-api/models/user'
import { businessUnitOptions } from '../../constants/businessUnits';
import { providerOptions } from '../../constants/providers';
import Select from 'react-select';
import './Users.css';
import { UsersService } from '../../services/UsersService';
import { pagesToDisplay } from '../../utils/pageHelper';
import Modal from '../WebComponents/Modal'
import Toast from '../WebComponents/Toast'
import { Link } from 'react-router-dom';

type UserFilterOptions = {
    mfaOptions: boolean[];
    statuses: string[];
};


const Users = () => {
    const componentRef = useRef(null);
    const [filterOptions, setFilterOptions] = useState<UserFilterOptions>({
        mfaOptions: [],
        statuses: [],
    });
    const [businessUnit, setBusinessUnit] = useState<string | null>(null);
    const [provider, setProvider] = useState<string | null>(null);
    const [neverLoggedIn, setNeverLoggedIn] = useState(false);
    const [userData, setUserData] = useState<User[]>([]);
    const [filteredData, setFilteredData] = useState<User[]>([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortedField, setSortedField] = useState<keyof User>('name');
    const [showTable, setShowTable] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const abortControllerRef = useRef<AbortController | null>(null);
    
    interface ToastState {
        show: boolean;
        message: string;
        type: 'success' | 'cancel';
    }
    const TOAST_DURATION_MS = 5000;
    const [toast, setToast] = useState<ToastState>({
        show: false,
        message: '',
        type: 'success'
    });

    const usersService = new UsersService();
    const usersPerPage = 50;
    let locale = Intl.DateTimeFormat().resolvedOptions().locale;

    useEffect(() => {
        let newData = [...userData];

        if (filterOptions.mfaOptions.length > 0) {
            newData = newData.filter(item => filterOptions.mfaOptions.includes(item.mfa!));
        }
        if (filterOptions.statuses.length > 0) {
            newData = newData.filter(item => filterOptions.statuses.includes(item.status!));
        }

        const keyField = sortedField as keyof User;
        if (sortOrder === 'asc') {
            newData.sort((a, b) => compareFields(a[keyField], b[keyField]));
        } else {
            newData.sort((a, b) => compareFields(b[keyField], a[keyField]));
        }
        setFilteredData(newData);
    }, [filterOptions, sortOrder, sortedField, userData]);

    const handleSelectionChange = (option: string, selectedOptions: any) => {
        const values = Array.isArray(selectedOptions)
            ? selectedOptions.map((option: any) => option.value)
            : [selectedOptions.value];
        setFilterOptions(prevOptions => ({
            ...prevOptions,
            [option]: values,
        }));
    };

    const getUniqueOptions = (data: User[], fieldName: keyof User) => {
        return data.reduce((acc: { value: string; label: string }[], current: User) => {
            if (!acc.some(item => item.value === current[fieldName]!.toString())) {
                acc.push({ value: current[fieldName]!.toString(), label: current[fieldName]!.toString() });
            }
            return acc;
        }, []);
    };

    const handleSort = (field: keyof User) => {
        if (sortedField === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder('asc');
            setSortedField(field);
        }
    };

    const handlePageChange = async (page: number) => {
        setCurrentPage(page);
        const filterRequest: FilterRequest = {
            businessUnit: businessUnit ? businessUnit : undefined,
            provider: provider ? provider : undefined,
            neverLoggedIn,
        };
        const pageRequest: PageRequest = {
            page,
            count: usersPerPage,
        };
        try {
            setLoading(true);
            const users = await usersService.fetchUsers(filterRequest, pageRequest);
            setUserData(users.users);
            setTotalUsers(users.total);
            setTotalPages(Math.ceil(users.total / usersPerPage));
        } finally {
            setLoading(false);
        }
    };

    const pages = (pages: number, currentPage: number) => {
        let pagestoDisplay = pagesToDisplay(currentPage, pages);
        return ([
            pagestoDisplay.map((item, index) => {
                let next = pagestoDisplay[index + 1] !== undefined;
                return [item.map(page => <li className={currentPage === page + 1 ? 'active' : ''}><a href={`#${page + 1}`} onClick={() => handlePageChange(page + 1)}>
                    {page + 1}
                </a></li>), next ? <li>...</li> : []]
            })
        ])
    }

    const formatDate = (dateToFormat: Date | null | undefined) => {
        if (dateToFormat === null || dateToFormat === undefined) {
            return '';
        }
        var date = new Date(dateToFormat);
        return `${date.toLocaleDateString(locale)} ${date.toLocaleTimeString(locale)}`;
    };

    const handlePasswordReset = (user: User) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };

    const handleConfirmPasswordReset = async () => {
        if (selectedUser) {

            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }

            const abortController = new AbortController();

            try {
                setLoading(true);
                abortControllerRef.current = abortController;
                await usersService.resetPassword(selectedUser.businessUnit!, selectedUser.provider!, selectedUser.login!);
    
                setIsModalOpen(false);
                setToast({ show: true, message: "Password reset request sent successfully.", type: 'success' });
            } catch (error) {

                if (error instanceof DOMException && error.name === 'AbortError') {
                    return;
                }

                setIsModalOpen(false);
                
                let errorMessage = "Failed to send password reset request.";
                
                if (error instanceof Error) {
                    errorMessage = error.message || errorMessage;
                } else if (typeof error === 'string') {
                    errorMessage = error;
                }
                
                setToast({ show: true, message: errorMessage, type: 'cancel' });
            } finally {
                setLoading(false);
            }
    
            setTimeout(() => setToast({ show: false, message: '', type: 'success' }), TOAST_DURATION_MS);
        }
    };

    function compareFields(a: string | boolean | Date | undefined | null, b: string | boolean | Date | undefined | null): number {
        if (a === null || a === undefined) {
            return 1;
        }
        if (b === null || b === undefined) {
            return -1;
        }
        if (a instanceof Date && b instanceof Date) {
            return a.getTime() - b.getTime();
        }
        if (typeof a === 'string' && typeof b === 'string') return a.localeCompare(b);
        if (typeof a === 'boolean' && typeof b === 'boolean') return a === b ? 0 : a ? 1 : -1;
        return 0;
    }

    return (
        <div>
            <div className="main-filter-section">
                <div className="main-filter-dropdown left-aligned">
                    <label>Business Unit*</label>
                    <Select isClearable
                        options={businessUnitOptions}
                        onChange={(selectedOption) => setBusinessUnit(selectedOption ? selectedOption.value : null)}
                    />
                </div>
                <div className="main-filter-dropdown left-aligned">
                    <label>Provider</label>
                    <Select isClearable
                        options={providerOptions}
                        onChange={(selectedOption) => setProvider(selectedOption ? selectedOption.value : null)}
                    />
                </div>
                <div className="main-filter-toggle">
                    <label className="switch">
                        <input
                            id="never-logged-in"
                            name="never-logged-in"
                            className="switch-control"
                            type="checkbox"
                            value="Never logged in"
                            onChange={(e) => setNeverLoggedIn(e.target.checked)}
                        />
                        <span className="switch-label">Never logged in</span>
                    </label>
                </div>
                <button
                    className="main-filter-button btn btn--primary btn--sm"
                    disabled={!businessUnit}
                    onClick={async () => {
                        await handlePageChange(currentPage);
                        setShowTable(true);
                    }}
                >
                    {loading ? 'Loading...' : 'Show Users'}
                </button>
            </div>


            {showTable && (
                <>
                    <table className="table table--colored firstcol-sticky firstcol-strong">
                        <thead>
                            <tr>
                                <th className="col-md-2">
                                    <div className="text-left align-header ">
                                        <span>Name</span>
                                        <span className={`icon icon--arrow-long-up_24 ${sortedField === 'name' && sortOrder === 'asc' ? 'icon--arrow-selected' : ''}`} onClick={() => handleSort('name')}></span>
                                        <span className={`icon icon--arrow-long-down_24 ${sortedField === 'name' && sortOrder === 'desc' ? 'icon--arrow-selected' : ''}`} onClick={() => handleSort('name')}></span>
                                    </div>
                                </th>
                                <th className="col-md-2">
                                    <div className="text-left align-header">
                                        <span>Login</span>
                                        <span className={`icon icon--arrow-long-up_24 ${sortedField === 'login' && sortOrder === 'asc' ? 'icon--arrow-selected' : ''}`} onClick={() => handleSort('login')}></span>
                                        <span className={`icon icon--arrow-long-down_24 ${sortedField === 'login' && sortOrder === 'desc' ? 'icon--arrow-selected' : ''}`} onClick={() => handleSort('login')}></span>
                                    </div>
                                </th>
                                <th className="col-md-1">
                                    <div>
                                        <div className="text-left align-header" style={{ marginBottom: 0 }} >
                                            <span>MFA</span>
                                            <span className={`icon icon--arrow-long-up_24 ${sortedField === 'mfa' && sortOrder === 'asc' ? 'icon--arrow-selected' : ''}`} onClick={() => handleSort('mfa')}></span>
                                            <span className={`icon icon--arrow-long-down_24 ${sortedField === 'mfa' && sortOrder === 'desc' ? 'icon--arrow-selected' : ''}`} onClick={() => handleSort('mfa')}></span>
                                        </div>
                                        <Select isMulti options={[{ value: true, label: 'YES' }, { value: false, label: 'NO' }]}
                                            onChange={(selectedOptions) => handleSelectionChange('mfaOptions', selectedOptions)}
                                            menuPortalTarget={componentRef.current}
                                            menuPlacement="auto" />
                                    </div>
                                </th>
                                <th className="col-md-2">
                                    <div className="text-left align-header">
                                        <span>Created</span>
                                        <span className={`icon icon--arrow-long-up_24 ${sortedField === 'createdAt' && sortOrder === 'asc' ? 'icon--arrow-selected' : ''}`} onClick={() => handleSort('createdAt')}></span>
                                        <span className={`icon icon--arrow-long-down_24 ${sortedField === 'createdAt' && sortOrder === 'desc' ? 'icon--arrow-selected' : ''}`} onClick={() => handleSort('createdAt')}></span>
                                    </div>
                                </th>
                                <th className="col-md-2">
                                    <div className="text-left align-header">
                                        <span>Last Login</span>
                                        <span className={`icon icon--arrow-long-up_24 ${sortedField === 'lastLogin' && sortOrder === 'asc' ? 'icon--arrow-selected' : ''}`} onClick={() => handleSort('lastLogin')}></span>
                                        <span className={`icon icon--arrow-long-down_24 ${sortedField === 'lastLogin' && sortOrder === 'desc' ? 'icon--arrow-selected' : ''}`} onClick={() => handleSort('lastLogin')}></span>
                                    </div>
                                </th>
                                <th className="col-md-3">
                                    <span>Status</span>
                                    <span className={`icon icon--arrow-long-up_24 ${sortedField === 'status' && sortOrder === 'asc' ? 'icon--arrow-selected' : ''}`} onClick={() => handleSort('status')}></span>
                                    <span className={`icon icon--arrow-long-down_24 ${sortedField === 'status' && sortOrder === 'desc' ? 'icon--arrow-selected' : ''}`} onClick={() => handleSort('status')}></span>
                                    <Select isMulti options={getUniqueOptions(userData, 'status')}
                                        onChange={(selectedOptions) => handleSelectionChange('statuses', selectedOptions)}
                                        menuPortalTarget={componentRef.current}
                                        menuPlacement="auto" />
                                </th>
                                <th className="col-md-3">
                                    <div className="text-left align-header">
                                        <span>Actions</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((user, index) => (
                                <tr key={index} className="table-row">
                                    <td className="col-md-2 text-left">{user.name}</td>
                                    <td className="col-md-2 text-left">{user.login}</td>
                                    <td className="col-md-2 text-left">{user.mfa ? 'YES' : 'NO'}</td>
                                    <td className="col-md-2 text-left">{formatDate(user.createdAt!)}</td>
                                    <td className="col-md-2 text-left">{formatDate(user.lastLogin)}</td>
                                    <td className="col-md-2 text-left">{user.status}</td>
                                    <td className="col-md-2 text-left">
                                        <Link to={`/users/${user.id}`} className="icon-button" 
                                            target="_blank" rel="noopener noreferrer">
                                            <span title='View details' className='icon icon--alert-circle_24_solid dark-blue'></span>
                                        </Link>
                                        <button
                                            title='Password reset'
                                            className='icon-button'
                                            onClick={() => handlePasswordReset(user)}
                                        >
                                            <span className='icon icon--lock-process_24_solid'></span>
                                        </button>
                                        <Link to={`/audit-log?login=${encodeURIComponent(user.login!)}`} target="_blank" rel="noopener noreferrer">
                                            <span title='View audit logs' className='icon icon--desktop_24_outline'/>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination-container">
                        <nav className="pagination">
                            <div className="pagination-numbers">
                                <ul>
                                    <li>
                                        {currentPage > 1 ? (
                                            <a href="#0" onClick={() => handlePageChange(currentPage - 1)}>
                                                <span className="icon icon--arrow-left_24"></span>
                                            </a>
                                        ) : (
                                            <span className="icon icon--arrow-left_24 disabled"></span>
                                        )}
                                    </li>
                                    {pages(totalPages, currentPage)}
                                    <li>
                                        {currentPage < totalPages ? (
                                            <a href="#0" onClick={() => handlePageChange(currentPage + 1)}>
                                                <span className="icon icon--arrow-right_24"></span>
                                            </a>
                                        ) : (
                                            <span className="icon icon--arrow-right_24 disabled"></span>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </nav>
                        <div className="total-users-nav">
                            (Total: {totalUsers} users )
                        </div>
                    </div>

                </>
            )}

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title="Confirm Password Reset"
                content={`You are about to send a password reset request to user ${selectedUser?.login}. Do you confirm?`}
                primaryButtonText="Reset Password"
                secondaryButtonText="Cancel"
                onPrimaryButtonClick={handleConfirmPasswordReset}
                onSecondaryButtonClick={() => setIsModalOpen(false)}
                loading={loading}
            />

            {toast.show && (
                <Toast
                    message={toast.message}
                    type={toast.type}
                    onClose={() => setToast({ ...toast, show: false })}
                />
            )}

        </div>
    );
};

export default Users;