import React, { useEffect, useRef, useState } from 'react';
import './UserDetails.css';
import { Link, useParams, } from 'react-router-dom';
import { GdpOptions } from '../../config/GdpOptions';
import { Configuration } from '../../api/out-gdp-ammi-dashboard-backend-api/configuration';
import { UsersApi } from '../../api/out-gdp-ammi-dashboard-backend-api';
import { ViewUser } from '../../api/out-gdp-ammi-dashboard-backend-api/models/view-user';
import Modal from '../WebComponents/Modal';
import Toast from '../WebComponents/Toast';
import { UsersService } from '../../services/UsersService';
import TextField from '../WebComponents/TextField';



const UserDetails = () => {
  const { id } = useParams();

  const [user, setUser] = useState({} as ViewUser);
  const [showUser, setShowUser] = useState(false);

  const [showIdpApps, setShowIdpApps] = useState(true);
  const [showAppRoles, setShowAppRoles] = useState(true);
  const [showBrokerIds, setShowBrokerIds] = useState(true);
  const [showCustomerOnboardRequests, setShowCustomerOnboardRequests] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const emptyFieldValue = '-';
  let locale = Intl.DateTimeFormat().resolvedOptions().locale;
  const usersService = new UsersService();
  const abortControllerRef = useRef<AbortController | null>(null);

  interface ToastState {
    show: boolean;
    message: string;
    type: 'success' | 'cancel';
  }
  const TOAST_DURATION_MS = 5000;
  const [toast, setToast] = useState<ToastState>({
      show: false,
      message: '',
      type: 'success'
  });

  const formatDate = (dateToFormat: Date | null | undefined) => {
    if (dateToFormat === null || dateToFormat === undefined) {
        return emptyFieldValue;
    }
    var date = new Date(dateToFormat);
    return `${date.toLocaleDateString(locale)} ${date.toLocaleTimeString(locale)}`;
  };

  const formatStringArray = (array: Array<string> | null | undefined) => {
    if (array === null || array === undefined || array.length === 0) {
        return emptyFieldValue;
    }
    var formatted =  array.join(', ');
    return formatted;
  };

  const setFieldsToBeShown = (user: ViewUser) => {
    if (user.customerOnboardRequests === null || 
      user.customerOnboardRequests === undefined || user.customerOnboardRequests.length === 0) {
      setShowCustomerOnboardRequests(false);
    }

    if (user.idpApps === null || 
      user.idpApps === undefined || user.idpApps.length === 0) {
      setShowIdpApps(false);
    }

    if (user.appRoles === null || 
      user.appRoles === undefined || user.appRoles.length === 0) {
      setShowAppRoles(false);
    }

    if (user.brokerIds === null || 
      user.brokerIds === undefined || user.brokerIds.length === 0) {
      setShowBrokerIds(false);
    }
  }

  useEffect(() => {
    async function getUserData() {
      try {
        const options = GdpOptions;
        const configuration = new Configuration();
        configuration.basePath = options.backendUrl;
        const client = new UsersApi(configuration);
        let result = await client.usersUserByIdGet(id);
        setUser(result.data);
        setShowUser(true);
        setFieldsToBeShown(result.data);
      } catch (error) {
        console.error(error);
        setShowUser(false);
      }
    }
    getUserData();
  }, [id]);

  const handlePasswordReset = (user: ViewUser) => {
    setIsModalOpen(true);
  };

  const handleConfirmPasswordReset = async () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        const abortController = new AbortController();

        try {
            setLoading(true);
            abortControllerRef.current = abortController;
            await usersService.resetPassword(user.businessUnit!, user.provider!, user.login!);

            setIsModalOpen(false);
            setToast({ show: true, message: "Password reset request sent successfully.", type: 'success' });
        } catch (error) {

            if (error instanceof DOMException && error.name === 'AbortError') {
                return;
            }

            setIsModalOpen(false);
            
            let errorMessage = "Failed to send password reset request.";
            
            if (error instanceof Error) {
                errorMessage = error.message || errorMessage;
            } else if (typeof error === 'string') {
                errorMessage = error;
            }
            
            setToast({ show: true, message: errorMessage, type: 'cancel' });
        } finally {
            setLoading(false);
        }

        setTimeout(() => setToast({ show: false, message: '', type: 'success' }), TOAST_DURATION_MS);
  };

  return (
  <div className='UserDetails'>
      {showUser && (
      <><div>
          <div className="d-flex">
            <div style={{ textAlign: 'left' }}>
              <h2 className="username">{user.name}</h2>
              <span className="user-id">{user.id}</span>
            </div>
            <div style={{ textAlign: 'right' }}>
              <div className="button-group">
                <button className="btn btn--primary btn--sm">
                  <Link to={`/audit-log?login=${encodeURIComponent(user.login!)}`} className="no-underline" 
                        target="_blank" rel="noopener noreferrer">Audit logs
                  </Link>
                </button>
                <button className="btn btn--primary btn--sm" onClick={() => handlePasswordReset(user)}>Reset password</button>
                <button className="btn btn--primary btn--sm" disabled={true}>Edit</button>
                <button className="btn btn--primary btn--sm" disabled={true}>Delete</button>
              </div>
            </div>
          </div>
        </div><div className="loginInfo">
            <table className="table">
              <tbody>
                <tr>
                  <td className="loginInfoFirstColumn bold">Status</td>
                  <td>{user.status}</td>
                  <td className="loginInfoSecondColumn bold">Created On</td>
                  <td>{formatDate(user.createdDate)}</td>
                </tr>
                <tr>
                  <td className="loginInfoFirstColumn bold">Last update on</td>
                  <td>{formatDate(user.lastUpdateDate)}</td>
                  <td className="loginInfoSecondColumn bold">Last login on</td>
                  <td>{formatDate(user.lastLoginDate)}</td>
                </tr>
                <tr>
                  <td className="loginInfoFirstColumn bold">Changed password on</td>
                  <td>{formatDate(user.passwordChangeDate)}</td>
                  <td className="loginInfoSecondColumn bold">Customer ID</td>
                  <td>{formatStringArray(user.customerIds)}</td>
                </tr>
              </tbody>
            </table>
          </div><div className="profileInformation">
            <div className="profileInformationTitle">Profile details</div>
            <div className="row">
              <div className="col-md-2 profileInfoFirstColumn">
                <TextField id="field-input-business-unit"
                           value={user.businessUnit || emptyFieldValue}
                           disabled={true}
                           label="Business Unit"/>
              </div>
              <div className="col-md-2 profileInfoSecondColumn">
                <TextField id="field-input-provider"
                           value={user.provider || emptyFieldValue}
                           disabled={true}
                           label="Provider"/>                
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 profileInfoFirstColumn">
                <TextField id="field-input-role"
                           value={formatStringArray(user.roles)}
                           disabled={true}
                           label="Role"/> 
              </div>
              <div className="col-md-2 profileInfoSecondColumn">
                <TextField id="field-input-mfa"
                           value={formatStringArray(user.mfa)}
                           disabled={true}
                           label="MFA"/>                
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 profileInfoFirstColumn">
                <TextField id="field-input-title"
                           value={user.title || emptyFieldValue}
                           disabled={true}
                           label="Title"/>              
              </div>
              <div className="col-md-2 profileInfoSecondColumn">
                <TextField id="field-input-salutation"
                           value={user.salutation || emptyFieldValue}
                           disabled={true}
                           label="Salutation"/>               
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 profileInfoFirstColumn">
                <TextField id="field-input-firstname"
                           value={user.firstName || emptyFieldValue}
                           disabled={true}
                           label="First name"/>                
              </div>
              <div className="col-md-2 profileInfoSecondColumn">
                <TextField id="field-input-lastname"
                           value={user.lastName || emptyFieldValue}
                           disabled={true}
                           label="Last name"/>               
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 profileInfoFirstColumn">
                <TextField id="field-input-name"
                           value={user.name || emptyFieldValue}
                           disabled={true}
                           label="Display name"/>               
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 profileInfoFirstColumn">
                <TextField id="field-input-credentials-login"
                           value={user.login || emptyFieldValue}
                           disabled={true}
                           label="Credentials - Login"/>                
              </div>
              <div className="col-md-2 profileInfoSecondColumn">
                <TextField id="field-input-credentials-email"
                           value={user.email || emptyFieldValue}
                           disabled={true}
                           label="Credentials - Email"/>               
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 profileInfoFirstColumn">
                <TextField id="field-input-contact-phone"
                           value={user.contactPhone || emptyFieldValue}
                           disabled={true}
                           label="Contact - Phone"/>
              </div>
              <div className="col-md-2 profileInfoSecondColumn">
                <TextField id="field-input-contact-email"
                           value={user.contactEmail || emptyFieldValue}
                           disabled={true}
                           label="Contact - Email"/>               
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 profileInfoFirstColumn">
                <TextField id="field-input-full-address"
                           value={user.address?.fullAddress || emptyFieldValue}
                           disabled={true}
                           label="Full Address"/>                
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 profileInfoFirstColumn">
                <TextField id="field-input-address-street"
                           value={user.address?.street || emptyFieldValue}
                           disabled={true}
                           label="Address - Street"/>              
              </div>
              <div className="col-md-2 profileInfoSecondColumn">
                <TextField id="field-input-address-number"
                           value={user.address?.number || emptyFieldValue}
                           disabled={true}
                           label="Address - Number"/>               
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 profileInfoFirstColumn">
                <TextField id="field-input-address-postcode"
                           value={user.address?.postCode || emptyFieldValue}
                           disabled={true}
                           label="Address - Postcode"/>             
              </div>
              <div className="col-md-2 profileInfoSecondColumn">
                <TextField id="field-input-address-city"
                           value={user.address?.city || emptyFieldValue}
                           disabled={true}
                           label="Address - City"/>               
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 profileInfoFirstColumn">
                <TextField id="field-input-address-country"
                           value={user.address?.country || emptyFieldValue}
                           disabled={true}
                           label="Address - Country"/>            
              </div>
            </div>
          </div><div className="groupsAndApplications">
            <div className="groupsAndApplicationsTitle">Groups and Applications</div>
            <table className="table">
              <tbody>
                <tr>
                  <td className="groupsAppsFirstColumn bold">Groups</td>
                  <td>{formatStringArray(user.groups)}</td>
                </tr>
                <tr>
                  <td className="groupsAppsFirstColumn bold">Applications</td>
                  <td>{formatStringArray(user.applications)}</td>
                </tr>
                {showIdpApps && (
                <tr>
                  <td className="groupsAppsFirstColumn bold">IDP Apps</td>
                  <td>{formatStringArray(user.idpApps)}</td>
                </tr>
                )}
                {showAppRoles && (
                <tr>
                  <td className="groupsAppsFirstColumn bold">App roles</td>
                  <td>{formatStringArray(user.appRoles)}</td>
                </tr>
                )}
                {showBrokerIds && (
                <tr>
                  <td className="groupsAppsFirstColumn bold">Broker IDs</td>
                  <td>{formatStringArray(user.brokerIds)}</td>
                </tr>
                )}
              </tbody>
            </table>
          </div>
          {showCustomerOnboardRequests && (
          <div className="customerOnboardRequests">
            <div className="customerOnboardRequestsTitle">Customer Onboard Requests</div>
            <table className="table">
              <thead>
                <tr>
                  <th className="col-md-2 no-left-padding customerOnboardRequestsFirstColumn">
                    <div className="text-left customerOnboardRequestsHeaderFirstColumn">
                      <span>Request ID</span>
                    </div>
                  </th>
                  <th className="col-md-2">
                    <div className="text-left">
                      <span>Created on</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {user.customerOnboardRequests?.map((request, index) => (
                  <tr key={index}>
                    <td className="customerOnboardRequestsFirstColumn">{request.requestId || emptyFieldValue}</td>
                    <td>{formatDate(request.createdDate)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>)}</>)}
          
          <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title="Confirm Password Reset"
                content={`You are about to send a password reset request to user ${user.login}. Do you confirm?`}
                primaryButtonText="Reset Password"
                secondaryButtonText="Cancel"
                onPrimaryButtonClick={handleConfirmPasswordReset}
                onSecondaryButtonClick={() => setIsModalOpen(false)}
                loading={loading}
            />

            {toast.show && (
                <Toast
                    message={toast.message}
                    type={toast.type}
                    onClose={() => setToast({ ...toast, show: false })}
                />
            )}
  </div>
  ) 
};

export default UserDetails;
