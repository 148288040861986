import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';
import NavigationHeader from './components/NavigationHeader/NavigationHeader'
import LeftSideMenu from './components/LeftSideMenu/LeftSideMenu';
import MainContent from './components/MainContent/MainContent';
import ViewClientAuthorizations from './components/ViewClientAuthorizations/ViewClientAuthorizations';
import UpdateClientAuthorizations from './components/UpdateClientAuthorizations/UpdateClientAuthorizations';
import ApiHealth from './components/ApiHealth/ApiHealth';
import RevokeClientAuthorizations from './components/RevokeClientAuthorizations/RevokeClientAuthorizations';
import HistoryLog from './components/HistoryLog/HistoryLog';
import Users from './components/Users/Users';
import SAMLResponseHandler from './components/Auth/SAMLResponseHandler';
import { AuthenticationApi, Configuration, UsersApi } from './api/out-gdp-ammi-dashboard-backend-api';
import { GdpOptions } from './config/GdpOptions';
import UserDetails from './components/UserDetails/UserDetails';
import AuditLog from './components/AuditLog/AuditLog';

function App() {
  const environmentName = '#{releaseEnv}#';
  const [selectedItem, setSelectedItem] = useState('');

  const handleItemClick = (item: string) => {
      setSelectedItem(item);
  };

  const [userName, setUserName] = useState('');
  const enforceLogin = async () => {
    const options = GdpOptions;
    const configuration = new Configuration();
    configuration.basePath = options.backendUrl;
    const client = new AuthenticationApi(configuration);
    let result = await client.authenticationIsAuthenticatedGet();
    if(result.data.isAuthenticated)
    {
      let userClient = new UsersApi(configuration);
      let user = await userClient.usersUserGet(result.data.name);
      setUserName(user.data.name ?? result.data.name);
    }
    else
    {
      window.location.href = `${options.backendUrl}/api/authentication/sso-login`;
    }
  }
  enforceLogin();


  return (
    <div className="App">
      <Router>
        <NavigationHeader name={userName}></NavigationHeader>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <LeftSideMenu onItemClick={handleItemClick} />
                </div>
                <div className="col-md-10">
                  <Routes>
                    <Route path="/saml2/acs" Component={SAMLResponseHandler} />
                    <Route path="/api-health" Component={ApiHealth} />
                    <Route path="/view-client-authorizations" Component={ViewClientAuthorizations} />
                    <Route path="/update-client-authorizations" Component={UpdateClientAuthorizations} />
                    <Route path="/revoke-client-authorizations" Component={RevokeClientAuthorizations} />
                    <Route path="/history-log" Component={HistoryLog} />
                    <Route path="/audit-log" Component={AuditLog} />
                    <Route path="/users" Component={Users} />
                    <Route path="/users/:id" Component={UserDetails} />
                    <Route path="/" Component={MainContent} />
                  </Routes>
                </div>
            </div>
        </div>
      </Router>      
    </div>
  );
}



export default App;
