import React, { FC } from 'react';
import './RevokeClientAuthorizations.css';

interface RevokeClientAuthorizationsProps {}

const RevokeClientAuthorizations: FC<RevokeClientAuthorizationsProps> = () => (
  <div className="RevokeClientAuthorizations">
    RevokeClientAuthorizations Component
  </div>
);

export default RevokeClientAuthorizations;
