import { UsersApi, Configuration } from '../api/out-gdp-ammi-dashboard-backend-api';
import { FilterRequest, PageRequest } from '../contracts/User';
import { User, UsersResponse } from '../api/out-gdp-ammi-dashboard-backend-api/models';
import { GdpOptions } from '../config/GdpOptions';
import axios, { AxiosResponse, AxiosError } from 'axios';

export class UsersService {
  private getConfiguration(): Configuration {
    const configuration = new Configuration();
    configuration.basePath = GdpOptions.backendUrl;
    return configuration;
  }

  async fetchUsers(filterRequest?: FilterRequest, pageRequest?: PageRequest): Promise<{ users: User[], total: number }> {
    const usersApi = new UsersApi(this.getConfiguration());

    try {
      const usersResponse: AxiosResponse<UsersResponse> = await (usersApi as any).usersUsersGet(
        filterRequest?.status,
        filterRequest?.businessUnit,
        filterRequest?.provider,
        filterRequest?.neverLoggedIn,
        pageRequest?.page,
        pageRequest?.count
      );

      const dataForUI = usersResponse.data.users || [];
      const total = usersResponse.data.total || 0;

      return { users: dataForUI, total };
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  }

  async resetPassword(
    businessUnit: string,
    provider: string,
    login: string,
    signal?: AbortSignal
  ): Promise<void> {
    const api = new UsersApi(this.getConfiguration());

    try {
      const resetPasswordCommand = {
        businessUnit: businessUnit,
        provider: provider,
        login: login
      };

      await api.usersResetPasswordPost(resetPasswordCommand as any, { signal });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data) {
          throw new Error(axiosError.response.data.message as string);
        }
      }

      throw error;
    }
  }
}