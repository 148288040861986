import React, { FC } from 'react';
import './MainContent.css';

interface MainContentProps {
}

const MainContent: FC<MainContentProps> = () => {
    return (
        <div className="MainContent">
            <h2>AMMI Dashboard Main Page</h2>
        </div>
    );
};

export default MainContent;