import React, { FC } from 'react';
import './TableHeaderColumn.css';

interface TableHeaderColumnProps {
  className: string;
  columnName: string;
  label: string;
  sortedField: string;
  sortOrder: string;
  handleSort: (columnName: string) => void;
}

const TableHeaderColumn: FC<TableHeaderColumnProps> = ({ className, columnName, label, sortedField, sortOrder, handleSort }) => (
    <th className={className}>
        <div className="text-left align-header ">
            <span>{label}</span>
            <span className={`icon icon--arrow-long-up_24 ${sortedField === columnName && 
                sortOrder === 'asc' ? 'icon--arrow-selected' : ''}`} 
                onClick={() => handleSort(columnName)}></span>
            <span className={`icon icon--arrow-long-down_24 ${sortedField === columnName && 
                sortOrder === 'desc' ? 'icon--arrow-selected' : ''}`} 
                onClick={() => handleSort(columnName)}></span>
        </div>
    </th>
);

export default TableHeaderColumn;