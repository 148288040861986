import React from 'react';
import './Toast.css';


interface ToastProps {
  message: string;
  type: 'success' | 'cancel';
  onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, type, onClose }) => {
  return (
    <div className="toast-container">
      <div className={`toast toast-${type}`}>
        <span>{message}</span>
        <button onClick={onClose} className="toast-close">&times;</button>
      </div>
    </div>
  );
};

export default Toast;