import React, { useEffect, useRef, useState } from 'react';
import '@zurich/web-sdk/css/styles.min.css';
import './AuditLog.css';
import { auditLogActionTypes, AuditLogQueryParameter } from '../../constants/auditLogActionTypes';
import { businessUnitOptions } from '../../constants/businessUnits';
import { ZrDateInput } from '@zurich/web-components/react';
import { ZrSelect } from '@zurich/web-components/react';
import { ZrTextInput } from '@zurich/web-components/react';
import { ZrButton } from '@zurich/web-components/react';
import Select from 'react-select';
import { pagesToDisplay } from '../../utils/pageHelper';
import { AuditLogSearchRequest } from '../../api/out-gdp-ammi-dashboard-backend-api/models/audit-log-search-request';
import { AuditLogService } from '../../services/AuditLogService';
import { PageRequest } from '../../api/out-gdp-ammi-dashboard-backend-api/models/page-request';
import Toast from '../WebComponents/Toast';
import { compareFields } from '../../utils/sortHelper';
import TableHeaderColumn from '../WebComponents/TableHeaderColumn';
import { AuditLogTableRow } from '../../api/out-gdp-ammi-dashboard-backend-api/models/audit-log-table-row';
import { useSearchParams } from 'react-router-dom';
import { AuditLogPagedResponse } from '../../api/out-gdp-ammi-dashboard-backend-api';

type AuditLogFilterOptions = {
  actions: string[];
};

const AuditLog = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const loginQueryParameter = useRef<string | null>(searchParams.get(AuditLogQueryParameter.Login));
  const clientIdQueryParameter = useRef<string | null>(searchParams.get(AuditLogQueryParameter.ClientId));

  const loadRequired = useRef<boolean>(loginQueryParameter.current != undefined || clientIdQueryParameter.current != undefined);
  const componentRef = useRef(null);

  const [loginValue, setLoginValue] = useState<string | null>(loginQueryParameter.current);
  const [adminLoginValue, setAdminLoginValue] = useState<string | null>(null);
  const [entityIdValue, setEntityIdValue] = useState<string | null>(null);
  const [clientIdValue, setClientIdValue] = useState<string | null>(clientIdQueryParameter.current);
  const [selectedActionType, setSelectedActionType] = useState<string | null>(null);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<string | null>(null);
  const [publishedFrom, setPublishedFrom] = useState<string | null>(null);
  const [publishedUpTo, setPublishedUpTo] = useState<string | null>(null);

  const [showTable, setShowTable] = useState(false);
  const [filteredData, setFilteredData] = useState<AuditLogTableRow[]>([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedField, setSortedField] = useState<keyof AuditLogTableRow>('login');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [auditLogData, setAuditLogData] = useState<AuditLogTableRow[]>([]);
  const [totalAuditLogs, setTotalAuditLogs] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const abortControllerRef = useRef<AbortController | null>(null);

  const [filterOptions, setFilterOptions] = useState<AuditLogFilterOptions>({
    actions: [],
  });

  const auditLogService = new AuditLogService();
  const auditLogsPerPage = 50;
  let locale = Intl.DateTimeFormat().resolvedOptions().locale;

  interface ToastState {
    show: boolean;
    message: string;
    type: 'success' | 'cancel';
  }

  const [toast, setToast] = useState<ToastState>({
      show: false,
      message: '',
      type: 'success'
  });

  const isSearchDisabled = () => {
    return (
      ((loginValue === null || loginValue === '' )&&
      (adminLoginValue === null || adminLoginValue === '') &&
      (entityIdValue === null || entityIdValue === '') &&
      (clientIdValue === null || clientIdValue === '') &&
      selectedActionType === null &&
      selectedBusinessUnit === null &&
      publishedFrom === null &&
      publishedUpTo === null) ||
      loading
    );
  };

  const handleSelectionChange = (option: string, selectedOptions: any) => {
    const values = Array.isArray(selectedOptions)
        ? selectedOptions.map((option: any) => option.value)
        : [selectedOptions.value];
    setFilterOptions(prevOptions => ({
        ...prevOptions,
        [option]: values,
    }));
  };

  const auditLogActionTypesMapper = (auditLogActionTypes: any[]) =>
    auditLogActionTypes.map((item) => ({
      value: item.value,
      label: item.text,
    }));
  
  const mappedActionTypes = auditLogActionTypesMapper(auditLogActionTypes);

  const businessUnitsMapper = (businessUnits: any[]) =>
    businessUnits.map((item) => ({
      value: item.value,
      text: item.label,
    }));
  
  const mappedBusinessUnits = businessUnitsMapper(businessUnitOptions);

  const handleSort = (field: keyof AuditLogTableRow) => {
    if (sortedField === field) {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
        setSortOrder('asc');
        setSortedField(field);
    }
  };

  const formatDate = (dateToFormat: Date | null | undefined) => {
    if (dateToFormat === null || dateToFormat === undefined) {
        return '';
    }
    var date = new Date(dateToFormat);
    return `${date.toLocaleDateString(locale)} ${date.toLocaleTimeString(locale)}`;
  };


async function search(){
  const pageRequest: PageRequest = {
    limit: auditLogsPerPage,
    number: currentPage,
  };
  const auditLogSearchRequest: AuditLogSearchRequest = {
      login: loginValue ? loginValue : undefined,
      adminLogin: adminLoginValue ? adminLoginValue : undefined,
      businessUnit: selectedBusinessUnit ? selectedBusinessUnit : undefined,
      entityId: entityIdValue ? entityIdValue : undefined,
      originatingClientId: clientIdValue ? clientIdValue : undefined,
      actionType: selectedActionType ? selectedActionType : undefined,
      publishedFrom: publishedFrom ? new Date(publishedFrom) : undefined,
      publishedUpTo: publishedUpTo ? new Date(publishedUpTo) : undefined,
      page: pageRequest
  };

  const auditLogs = await fetchAuditLogData(auditLogSearchRequest);
  if (auditLogs) {
    setAuditLogData(auditLogs.results!);
    setTotalAuditLogs(auditLogs.total!);
    setTotalPages(Math.ceil(auditLogs.total! / auditLogsPerPage));
    setShowTable(auditLogs.results!.length > 0);
  } 
}

async function fetchAuditLogData(auditLogSearchRequest: AuditLogSearchRequest) : Promise<AuditLogPagedResponse | null>{
  setLoading(true);   
   
  try {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const abortController = new AbortController();

    abortControllerRef.current = abortController;
    var response = await auditLogService.fetchAuditLogs(auditLogSearchRequest, abortController.signal);
    
    if (response.results!.length === 0) {
      setToast({ show: true, 
        message: 'No audit log found with specified search criteria', 
        type: 'success' });
    }
    return response;
  } 
  catch (error) {
    console.log(error);
    let errorMessage = "Failed to load audit logs.";
    if (error instanceof Error) {
      if (error.name === 'CanceledError') {
        return null;
      }
      errorMessage = error.message || errorMessage;
    } else if (typeof error === 'string') {
        errorMessage = error;
    }
  
    setToast({ show: true, message: errorMessage, type: 'cancel' });
  }
  finally {
      setLoading(false);
  }
  return null;
}

  const pages = (pages: number, currentPage: number) => {
    let pagestoDisplay = pagesToDisplay(currentPage, pages);
    return ([
        pagestoDisplay.map((item, index) => {
            let next = pagestoDisplay[index + 1] !== undefined;
            return [item.map(page => <li key={page} className={currentPage === page + 1 ? 'active' : ''}><a href={`#${page + 1}`} onClick={() => setCurrentPage(page + 1)}>
                {page + 1}
            </a></li>), next ? <li key="dots">...</li> : []]
        })
    ])
  }

useEffect(() => {
  if (loadRequired.current) {
    search();
  }
}, [currentPage])

  useEffect(() => {
    let newData = [...auditLogData];
    
    if (filterOptions.actions.length > 0) {
        newData = newData.filter(item => filterOptions.actions.includes(item.action!));
    }

    const keyField = sortedField as keyof AuditLogTableRow;
    if (sortOrder === 'asc') {
        newData.sort((a, b) => compareFields(a[keyField], b[keyField]));
    } else {
        newData.sort((a, b) => compareFields(b[keyField], a[keyField]));
    }
    setFilteredData(newData);
  }, [filterOptions, sortOrder, sortedField, auditLogData]);

  return (
  <div className="AuditLog">
    <div className="row">
      <div className="col-md-3">
        <ZrTextInput value={loginQueryParameter.current ?? undefined} label="Login" onChange={(value) => setLoginValue(value)}/>
      </div>
      <div className="col-md-3">
        <ZrTextInput label="Admin Login" onChange={(value) => setAdminLoginValue(value)}>
        </ZrTextInput>              
      </div>
    </div>
    <div className="row">
      <div className="col-md-3">
          <ZrSelect
            label="Action types"
            options={auditLogActionTypes}
            onChange={(selectedOption) => setSelectedActionType(selectedOption ? selectedOption : null)}
          >
          </ZrSelect>
      </div>
      <div className="col-md-3">
          <ZrSelect
            label="Business unit"
            options={mappedBusinessUnits}
            onChange={(selectedOption) => setSelectedBusinessUnit(selectedOption ? selectedOption : null)}
          >
          </ZrSelect>               
      </div>
    </div>
    <div className="row">
      <div className="col-md-3">
        <ZrTextInput label="Entity ID" onChange={(value) => setEntityIdValue(value)}>
        </ZrTextInput>
      </div>
      <div className="col-md-3">
        <ZrTextInput value={clientIdQueryParameter.current ?? undefined} label="Client ID" onChange={(value) => setClientIdValue(value)}/>
      </div>
    </div>
    <div className="row">
      <div className="col-md-3">
        <ZrDateInput label="Published from" 
          input-type="date"
          onChange={(value) => setPublishedFrom(value)}>
        </ZrDateInput>
      </div>
      <div className="col-md-3">
        <ZrDateInput label="Published up to"
          input-type="date"
          onChange={(value) => setPublishedUpTo(value)}>
        </ZrDateInput>                
      </div>
    </div>
    <div className="row">
      <div className="col-md-6">
        <div className="text-right">
          <ZrButton disabled={isSearchDisabled()}
                    onClick={async () => {
                      loadRequired.current = true;
                      search();    
          }}>
            {loading ? 'Loading...' : 'Search'}
          </ZrButton>             
        </div>             
      </div>
    </div>

    {showTable && (
                <>
                    <table className="table table--colored firstcol-sticky firstcol-strong">
                        <thead>
                            <tr>
                                <TableHeaderColumn
                                  className="col-md-1"
                                  columnName="login"
                                  label="Login"
                                  sortedField={sortedField}
                                  sortOrder={sortOrder}
                                  handleSort={() => handleSort('login')}/>
                                <TableHeaderColumn
                                  className="col-md-1"
                                  columnName="adminLogin"
                                  label="Admin login"
                                  sortedField={sortedField}
                                  sortOrder={sortOrder}
                                  handleSort={() => handleSort('adminLogin')}/>                          
                                <TableHeaderColumn
                                  className="col-md-1"
                                  columnName="businessUnit"
                                  label="Business unit"
                                  sortedField={sortedField}
                                  sortOrder={sortOrder}
                                  handleSort={() => handleSort('businessUnit')}/>                             
                                <th className="col-md-2">
                                    <div>
                                        <div className="text-left align-header" style={{ marginBottom: 0 }} >
                                            <span>Action</span>
                                            <span className={`icon icon--arrow-long-up_24 ${sortedField === 'action' && sortOrder === 'asc' ? 'icon--arrow-selected' : ''}`} onClick={() => handleSort('action')}></span>
                                            <span className={`icon icon--arrow-long-down_24 ${sortedField === 'action' && sortOrder === 'desc' ? 'icon--arrow-selected' : ''}`} onClick={() => handleSort('action')}></span>
                                        </div>
                                        <Select isMulti options={mappedActionTypes}
                                            onChange={(selectedOptions) => handleSelectionChange('actions', selectedOptions)}
                                            menuPortalTarget={componentRef.current}
                                            menuPlacement="auto" />
                                    </div>
                                </th>
                                <TableHeaderColumn
                                  className="col-md-1"
                                  columnName="publishedOn"
                                  label="Published on"
                                  sortedField={sortedField}
                                  sortOrder={sortOrder}
                                  handleSort={() => handleSort('publishedOn')}/>                              
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((auditLog, index) => (
                                <tr key={index} className="table-row">
                                    <td className="col-md-1 text-left">{auditLog.login}</td>
                                    <td className="col-md-1 text-left">{auditLog.adminLogin}</td>
                                    <td className="col-md-1 text-left">{auditLog.businessUnit}</td>
                                    <td className="col-md-2 text-left">{auditLog.action}</td>
                                    <td className="col-md-1 text-left">{formatDate(auditLog.publishedOn)}</td>                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination-container">
                        <nav className="pagination">
                            <div className="pagination-numbers">
                                <ul>
                                    <li key="previous">
                                        {currentPage > 1 ? (
                                            <a href="#0" onClick={() => setCurrentPage(currentPage-1)}>
                                                <span className="icon icon--arrow-left_24"></span>
                                            </a>
                                        ) : (
                                            <span className="icon icon--arrow-left_24 disabled"></span>
                                        )}
                                    </li>
                                    {pages(totalPages, currentPage)}
                                    <li key="next">
                                        {currentPage < totalPages ? (
                                            <a href="#0" onClick={() => setCurrentPage(currentPage-1)}>
                                                <span className="icon icon--arrow-right_24"></span>
                                            </a>
                                        ) : (
                                            <span className="icon icon--arrow-right_24 disabled"></span>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </nav>
                        <div className="total-users-nav">
                            (Total: {totalAuditLogs} Audit logs)
                        </div>
                    </div>

                </>
            )}

            {toast.show && (
                <Toast
                    message={toast.message}
                    type={toast.type}
                    onClose={() => setToast({ ...toast, show: false })}
                />
            )}
  </div>
  );
};

export default AuditLog;
