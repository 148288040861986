import React, { FC } from 'react';
import './ApiHealth.css';

interface ApiHealthProps {}

const ApiHealth: FC<ApiHealthProps> = () => (
  <div className="ApiHealth">
    ApiHealth Component
  </div>
);

export default ApiHealth;
