import React from 'react';
import '@zurich/web-sdk/css/styles.min.css';
import './Modal.css';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick: () => void;
  loading: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  content,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  loading,
}) => {
  if (!isOpen) return null;

  return (
    <div className={`modal ${isOpen ? 'modal-open' : ''}`} data-modal="myModal">
      <div className="modal-backdrop" data-modal-toggle="myModal">
        <div className="modal-dialog modal-dialog--lg" data-modal-dialog="myModal">
          <button className="modal-close" onClick={onClose} disabled={loading}>
            <span className="icon icon--close_24"></span>
          </button>
          <div className="modal-content modal-centered">
            <h5 className="c-darkblue">{title}</h5>
            <p className="m-b-6 copy-small c-darkblue">{content}</p>
            <div>
              <button 
                className="btn btn--secondary" 
                onClick={onSecondaryButtonClick} 
                disabled={loading}
              >
                {secondaryButtonText}
              </button>
              <button 
                className="btn btn--primary" 
                onClick={onPrimaryButtonClick} 
                disabled={loading}
              >
                {loading ? 'Processing...' : primaryButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;