import { ClientAuthorization, ClientAuthorizationApiAuthorization, ClientAuthorizationApiEndpoint } from "../api/out-gdp-ammi-dashboard-backend-api";

export type ClientAuthorizationUI = {
    clientId: string;
    clientDescription: string;
    apiAuthorization: string;
    endpoint: string;
    method: string;
    whiteListed: boolean,
    internalClient: boolean,
    jwtRequired: boolean
  };


  export function ClientAuthorizationUIFromApi(apiClient: ClientAuthorization, api: ClientAuthorizationApiAuthorization, apiEndpoint: ClientAuthorizationApiEndpoint, whitelisted: boolean): ClientAuthorizationUI {

    return {
      clientId: apiClient.clientId!,
      clientDescription: apiClient.clientDescription!,
      apiAuthorization: api.apiId!,
      endpoint: apiEndpoint.apiPath!,
      method: apiEndpoint.method!,
      whiteListed: whitelisted,
      internalClient: apiClient.isInternalClient!,
      jwtRequired: api.jwtRequired!
    }; 
  }