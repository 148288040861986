import React, { FC } from 'react';
import './UpdateClientAuthorizations.css';

interface UpdateClientAuthorizationsProps {}

const UpdateClientAuthorizations: FC<UpdateClientAuthorizationsProps> = () => (
  <div className="UpdateClientAuthorizations">
    UpdateClientAuthorizations Component
  </div>
);

export default UpdateClientAuthorizations;
