/* tslint:disable */
/* eslint-disable */
/**
 * GDP Dashboard AMMI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ResetPasswordCommand } from '../models';
import { User } from '../models';
import { UsersResponse } from '../models';
import { ViewUser } from '../models/view-user';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ResetPasswordCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersResetPasswordPost: async (body?: ResetPasswordCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers !== undefined && localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserByIdGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/userById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserGet: async (login?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (login !== undefined) {
                localVarQueryParameter['login'] = login;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [status] 
         * @param {string} [businessUnit] 
         * @param {string} [provider] 
         * @param {boolean} [neverLoggedIn] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUsersGet: async (status?: string, businessUnit?: string, provider?: string, neverLoggedIn?: boolean, page?: number, count?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (businessUnit !== undefined) {
                localVarQueryParameter['BusinessUnit'] = businessUnit;
            }

            if (provider !== undefined) {
                localVarQueryParameter['Provider'] = provider;
            }

            if (neverLoggedIn !== undefined) {
                localVarQueryParameter['NeverLoggedIn'] = neverLoggedIn;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ResetPasswordCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersResetPasswordPost(body?: ResetPasswordCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersResetPasswordPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserByIdGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ViewUser>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersUserByIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserGet(login?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<User>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersUserGet(login, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [status] 
         * @param {string} [businessUnit] 
         * @param {string} [provider] 
         * @param {boolean} [neverLoggedIn] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUsersGet(status?: string, businessUnit?: string, provider?: string, neverLoggedIn?: boolean, page?: number, count?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UsersResponse>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersUsersGet(status, businessUnit, provider, neverLoggedIn, page, count, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {ResetPasswordCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersResetPasswordPost(body?: ResetPasswordCommand, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return UsersApiFp(configuration).usersResetPasswordPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserByIdGet(id?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ViewUser>> {
            return UsersApiFp(configuration).usersUserByIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserGet(login?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<User>> {
            return UsersApiFp(configuration).usersUserGet(login, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [status] 
         * @param {string} [businessUnit] 
         * @param {string} [provider] 
         * @param {boolean} [neverLoggedIn] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUsersGet(status?: string, businessUnit?: string, provider?: string, neverLoggedIn?: boolean, page?: number, count?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<UsersResponse>> {
            return UsersApiFp(configuration).usersUsersGet(status, businessUnit, provider, neverLoggedIn, page, count, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {ResetPasswordCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public async usersResetPasswordPost(body?: ResetPasswordCommand, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return UsersApiFp(this.configuration).usersResetPasswordPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public async usersUserByIdGet(id?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ViewUser>> {
        return UsersApiFp(this.configuration).usersUserByIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [login] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public async usersUserGet(login?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<User>> {
        return UsersApiFp(this.configuration).usersUserGet(login, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [status] 
     * @param {string} [businessUnit] 
     * @param {string} [provider] 
     * @param {boolean} [neverLoggedIn] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public async usersUsersGet(status?: string, businessUnit?: string, provider?: string, neverLoggedIn?: boolean, page?: number, count?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<UsersResponse>> {
        return UsersApiFp(this.configuration).usersUsersGet(status, businessUnit, provider, neverLoggedIn, page, count, options).then((request) => request(this.axios, this.basePath));
    }
}
