import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function SAMLResponseHandler() {
  const navigate = useNavigate();

  useEffect(() => {
    // Extract the SAML response from the URL or POST data
    // This example assumes the response is in the URL query parameters
    const cookies = Cookies.get();
    const samlResponse = cookies["samlCookie"];

    if (samlResponse) {
      // Decode and process the SAML response as needed
      // For demonstration purposes, we'll assume the response is a JSON string
      const authResult = JSON.parse(atob(samlResponse));

      console.log("auth result logging in next line");
      console.log(authResult);

      // Save the result in localStorage
      localStorage.setItem('authResult', JSON.stringify(authResult));

      // Redirect to the home page or another part of your app
      navigate('/');
    }
  }, [navigate]);

  return <div>Processing login...</div>;
}

export default SAMLResponseHandler;