import React, { FC } from 'react';
import './TextField.css';

interface TextFieldProps {
  id: string;
  value: string;
  disabled: boolean;
  label: string;
}

const TextField: FC<TextFieldProps> = ({ id, value, disabled, label }) => (
  <div className="textfield">
    <input
      id={id}
      className="textfield-control"
      value={value}
      type="text"
      disabled={disabled}
    />
    <label className="textfield-label">{label}</label>
  </div>
);

export default TextField;
