import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import './ClientAuthorizationsTable.css';
import {ClientAuthorizationUI} from '../../contracts/ClientAuthorizationUI';

type FilterOptions = {
  clientIds: string[];
  clientDescriptions: string[];
  apiAuthorizations: string[];
  endpoints: string[];
  methods: string[];
  authorizationTypeOptions: boolean[];
  internalClientOptions: boolean[];
  jwtRequiredOptions: boolean[];
}

const ClientAuthorizationsTable = ({clientData}: {clientData: ClientAuthorizationUI[]}) => {
 
  const componentRef = useRef(null);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    clientIds: [],
    clientDescriptions: [],
    apiAuthorizations: [],
    endpoints: [],
    methods: [],
    authorizationTypeOptions: [],
    internalClientOptions: [],
    jwtRequiredOptions: []
  });
  const [filteredData, setFilteredData] = useState(clientData); 
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedField, setSortedField] = useState('clientDescription');

  useEffect(() => {
    let newData = [...clientData];

    if (filterOptions.clientIds.length > 0) {
      newData = newData.filter(item => filterOptions.clientIds.includes(item.clientId));
    }
    if (filterOptions.clientDescriptions.length > 0) {
      newData = newData.filter(item => filterOptions.clientDescriptions.includes(item.clientDescription));
    }
    if (filterOptions.apiAuthorizations.length > 0) {
      newData = newData.filter(item => filterOptions.apiAuthorizations.includes(item.apiAuthorization));
    }
    if (filterOptions.endpoints.length > 0) {
      newData = newData.filter(item => filterOptions.endpoints.includes(item.endpoint));
    }
    if (filterOptions.methods.length > 0) {
      newData = newData.filter(item => filterOptions.methods.includes(item.method));
    }
    if (filterOptions.authorizationTypeOptions.length > 0) {
      newData = newData.filter(item => filterOptions.authorizationTypeOptions.includes(item.whiteListed));
    }
    if (filterOptions.internalClientOptions.length > 0) {
      newData = newData.filter(item => filterOptions.internalClientOptions.includes(item.internalClient));
    }
    if (filterOptions.jwtRequiredOptions.length > 0) {
      newData = newData.filter(item => filterOptions.jwtRequiredOptions.includes(item.jwtRequired));
    }

    const keyField = sortedField as keyof ClientAuthorizationUI;
    if (sortOrder === 'asc') {
      newData.sort((a, b) => compareFields(a[keyField], (b[keyField])));
    } else {
      newData.sort((a, b) => compareFields(b[keyField], (a[keyField])));
    }
    setFilteredData(newData);
  }, [filterOptions]);

  const handleSelectionChange = (option: string, selectedOptions: any) => {
    const values = selectedOptions.map((option: any) => option.value);
    setFilterOptions(prevOptions => ({
      ...prevOptions,
      [option]: values
    }));
  };

  const getUniqueOptions = (filteredData: ClientAuthorizationUI[], fieldName: keyof ClientAuthorizationUI) => {
    return filteredData.reduce((acc: { value: string; label: string }[], current: ClientAuthorizationUI) => {
      if (!acc.some((item) => item.value === current[fieldName].toString())) {
        acc.push({ value: current[fieldName].toString(), label: current[fieldName].toString() });
      }
      return acc;
    }, []);
  };

  const handleSort = (field: keyof ClientAuthorizationUI) => {
    if (sortedField === field.toString()) {
      sortFilteredData(sortOrder, setSortOrder, filteredData, field);
    } 
    else {
      setSortOrder('desc');
      setSortedField(field.toString());
      sortFilteredData(sortOrder, setSortOrder, filteredData, field);
    }
  };

  const sortFilteredData = (sortOrder: string, setSortOrder: React.Dispatch<React.SetStateAction<string>>, 
    filteredData: ClientAuthorizationUI[], field: keyof ClientAuthorizationUI) => {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
        filteredData.sort((a, b) => compareFields(b[field],(a[field])));
      } else {
        setSortOrder('asc');
        filteredData.sort((a, b) => compareFields(a[field], (b[field])));
      }
  }

  function compareFields(a: string| boolean, b: string | boolean): number {
    if (typeof a === 'string' && typeof b === 'string') 
      return a.localeCompare(b)
    else 
      return a === b ? 0 : a > b ? 1 : a < b ? -1 : 0;
  }
    const activeSortIcon = (key: keyof ClientAuthorizationUI, direction: string) => {
    const isCurrentField = sortedField === key;
    if (isCurrentField && sortOrder === 'asc' && direction === 'up') {
      return 'icon--arrow-selected';
    }
    else if (isCurrentField && sortOrder === 'desc' && direction === 'down') {
      return 'icon--arrow-selected';
    }
    return '';
  };

  return (
    <div>
      <table className="table table--colored firstcol-sticky firstcol-strong">
        <thead>
          <tr>
            <th className="col-md-2">
              <div className="col-md-12 text-left zeroLeftPadding">
                <span>ClientId</span>
                <span className={`icon icon--arrow-long-up_24 ${activeSortIcon('clientId','up')}`} onClick={() => handleSort('clientId')}></span>
                <span className={`icon icon--arrow-long-down_24 ${activeSortIcon('clientId','down')}`} onClick={() => handleSort('clientId')}></span>
              </div>
              <div className="col-md-12 zeroLeftPadding">
                <Select isMulti options={getUniqueOptions(clientData, 'clientId')}
                        onChange={(selectedOptions) => handleSelectionChange('clientIds', selectedOptions)}
                        menuPortalTarget={componentRef.current}
                        menuPlacement="auto"/>
              </div>
            </th>
            <th className="col-md-2">
              <div className="col-md-12 text-left zeroLeftPadding">
                <span>Client Description</span>
                <span className={`icon icon--arrow-long-up_24 ${activeSortIcon('clientDescription','up')}`} onClick={() => handleSort('clientDescription')}></span>
                <span className={`icon icon--arrow-long-down_24 ${activeSortIcon('clientDescription','down')}`} onClick={() => handleSort('clientDescription')}></span>
              </div>
              <div className="col-md-12 zeroLeftPadding">
                <Select isMulti options={getUniqueOptions(clientData, 'clientDescription')}
                        onChange={(selectedOptions) => handleSelectionChange('clientDescriptions', selectedOptions)}
                        menuPortalTarget={componentRef.current}
                        menuPlacement="auto"/>
              </div>
            </th>
            <th className="col-md-2">
              <div className="col-md-12 text-left zeroLeftPadding">
                <span>Auhtorization Type</span>
                <span className={`icon icon--arrow-long-up_24 ${activeSortIcon('whiteListed','up')}`} onClick={() => handleSort('whiteListed')}></span>
                <span className={`icon icon--arrow-long-down_24 ${activeSortIcon('whiteListed','down')}`} onClick={() => handleSort('whiteListed')}></span>
              </div>
              <div className="col-md-12 zeroLeftPadding">
                <Select isMulti options={[ {value: true, label:'Whitelisted'}, {value: false, label: 'Blacklisted'}]}
                        onChange={(selectedOptions) => handleSelectionChange('authorizationTypeOptions', selectedOptions)}
                        menuPortalTarget={componentRef.current}
                        menuPlacement="auto"/>
              </div>
            </th>
            <th className="col-md-2">
              <div className="col-md-12 text-left zeroLeftPadding">
                <span>API Name</span>
                <span className={`icon icon--arrow-long-up_24 ${activeSortIcon('apiAuthorization','up')}`} onClick={() => handleSort('apiAuthorization')}></span>
                <span className={`icon icon--arrow-long-down_24 ${activeSortIcon('apiAuthorization','down')}`} onClick={() => handleSort('apiAuthorization')}></span>
              </div>
              <div className="col-md-12 zeroLeftPadding">
                <Select isMulti options={getUniqueOptions(clientData, 'apiAuthorization')}
                        onChange={(selectedOptions) => handleSelectionChange('apiAuthorizations', selectedOptions)}
                        menuPortalTarget={componentRef.current}
                        menuPlacement="auto"/>
              </div>
            </th>
            <th className="col-md-2">
              <div className="col-md-12 text-left zeroLeftPadding">
                <span>Internal Client</span>
                <span className={`icon icon--arrow-long-up_24 ${activeSortIcon('internalClient','up')}`} onClick={() => handleSort('internalClient')}></span>
                <span className={`icon icon--arrow-long-down_24 ${activeSortIcon('internalClient','down')}`} onClick={() => handleSort('internalClient')}></span>
              </div>
              <div className="col-md-12 zeroLeftPadding">
                <Select isMulti options={[ {value: true, label:'YES'}, {value: false, label: 'NO'}]}
                        onChange={(selectedOptions) => handleSelectionChange('internalClientOptions', selectedOptions)}
                        menuPortalTarget={componentRef.current}
                        menuPlacement="auto"/>
              </div>
            </th>
            <th className="col-md-2">
              <div className="col-md-12 text-left zeroLeftPadding">
                <span>Jwt Required</span>
                <span className={`icon icon--arrow-long-up_24 ${activeSortIcon('jwtRequired','up')}`} onClick={() => handleSort('jwtRequired')}></span>
                <span className={`icon icon--arrow-long-down_24 ${activeSortIcon('jwtRequired','down')}`} onClick={() => handleSort('jwtRequired')}></span>
              </div>
              <div className="col-md-12 zeroLeftPadding">
                <Select isMulti options={[ {value: true, label:'YES'}, {value: false, label: 'NO'}]}
                        onChange={(selectedOptions) => handleSelectionChange('jwtRequiredOptions', selectedOptions)}
                        menuPortalTarget={componentRef.current}
                        menuPlacement="auto"/>
              </div>
            </th>
            <th className="col-md-2">
              <div className="col-md-12 text-left zeroLeftPadding">
                <span>Endpoint</span>
                <span className={`icon icon--arrow-long-up_24 ${activeSortIcon('endpoint','up')}`} onClick={() => handleSort('endpoint')}></span>
                <span className={`icon icon--arrow-long-down_24 ${activeSortIcon('endpoint','down')}`} onClick={() => handleSort('endpoint')}></span>
              </div>
              <div className="col-md-12 zeroLeftPadding">
                <Select isMulti options={getUniqueOptions(clientData, 'endpoint')}
                        onChange={(selectedOptions) => handleSelectionChange('endpoints', selectedOptions)}
                        menuPortalTarget={componentRef.current}
                        menuPlacement="auto"/>
              </div>
            </th>
            <th className="col-md-2">
              <div className="col-md-12 text-left zeroLeftPadding">
                <span>Method</span>
                <span className={`icon icon--arrow-long-up_24 ${activeSortIcon('method','up')}`} onClick={() => handleSort('method')}></span>
                <span className={`icon icon--arrow-long-down_24 ${activeSortIcon('method','down')}`} onClick={() => handleSort('method')}></span>
              </div>
              <div className="col-md-12 zeroLeftPadding">
                <Select isMulti options={getUniqueOptions(clientData, 'method')}
                        onChange={(selectedOptions) => handleSelectionChange('methods', selectedOptions)}
                        menuPortalTarget={componentRef.current}
                        menuPlacement="auto"/>
              </div>
            </th>
            <th className="col-md-2">
              <div className="col-md-12 text-left zeroLeftPadding">
                <span>Actions</span>
              </div>
              <div className="col-md-12 zeroLeftPadding">
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td className="col-md-2 text-left">{item.clientId}</td>
              <td className="col-md-2 text-left">{item.clientDescription}</td>
              <td className="col-md-2 text-left">{item.whiteListed === true ? 'Whitelisted' : 'Blacklisted'}</td>
              <td className="col-md-2 text-left">{item.apiAuthorization}</td>
              <td className="col-md-2 text-left">{item.internalClient === true ? 'YES' : 'NO'}</td>
              <td className="col-md-2 text-left">{item.jwtRequired === true ? 'YES' : 'NO'}</td>
              <td className="col-md-2 text-left">{item.endpoint }</td>
              <td className="col-md-2 text-left">{item.method}</td>
              <td className="col-md-2 text-left">
                <span title='View details' className='icon icon--alert-circle_24_solid'> </span>
                <span title='Edit' className='icon icon--pencil_24_outline'></span>
                <span title='View audit logs' className='icon icon--desktop_24_outline'> </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClientAuthorizationsTable;


