export const auditLogActionTypes = [
    { value: 'AuthorizationGranted', text: 'AuthorizationGranted' },
    { value: 'AzureAPIMSubscriptionCreated', text: 'AzureAPIMSubscriptionCreated'  },
    { value: 'AzureAPIMSubscriptionUpdated', text: 'AzureAPIMSubscriptionUpdated' },
    { value: 'BrokerCreate', text: 'BrokerCreate' },
    { value: 'BrokerUpdate', text: 'BrokerUpdate' },
    { value: 'ChangesRequested', text: 'ChangesRequested' },
    { value: 'ClientDeleted', text: 'ClientDeleted' },
    { value: 'ContactUpdateEMAIL', text: 'ContactUpdateEMAIL' },
    { value: 'CustomerProfileChangesDeleted', text: 'CustomerProfileChangesDeleted' },
    { value: 'CustomerProfileChangesDelivered', text: 'CustomerProfileChangesDelivered' },
    { value: 'CustomerProfileChangesRequested', text: 'CustomerProfileChangesRequested' },
    { value: 'DocumentUploadedToDataCache', text: 'DocumentUploadedToDataCache' },
    { value: 'Download', text: 'Download' },
    { value: 'EMAILContactVerification', text: 'EMAILContactVerification' },
    { value: 'EMAILTriggerVerification', text: 'EMAILTriggerVerification' },
    { value: 'ForgotPasswordTriggered', text: 'ForgotPasswordTriggered' },
    { value: 'HardDeleted', text: 'HardDeleted' },
    { value: 'NewMessage', text: 'NewMessage' },
    { value: 'NewOnboardRequestForCorporateCustomer', text: 'NewOnboardRequestForCorporateCustomer' },
    { value: 'NewOnboardRequestForCustomer', text: 'NewOnboardRequestForCustomer' },
    { value: 'NewOnboardRequestForNewUser', text: 'NewOnboardRequestForNewUser' },
    { value: 'NewPushNotification', text: 'NewPushNotification' },
    { value: 'NewSelfOnboardRequest', text: 'NewSelfOnboardRequest' },
    { value: 'NewUserCreated', text: 'NewUserCreated' },
    { value: 'NotificationDeleted', text: 'NotificationDeleted' },
    { value: 'NotificationDelivered', text: 'NotificationDelivered' },
    { value: 'NotificationRead', text: 'NotificationRead' },
    { value: 'NotificationSoftDeleted', text: 'NotificationSoftDeleted' },
    { value: 'OnboardingCompleted', text: 'OnboardingCompleted' },
    { value: 'OnboardingToFalconFailed', text: 'OnboardingToFalconFailed' },
    { value: 'OnboardingToUserManagementFailed', text: 'OnboardingToUserManagementFailed' },
    { value: 'OnboardingToUserPermissionsFailed', text: 'OnboardingToUserPermissionsFailed' },
    { value: 'PasswordReset', text: 'PasswordReset' },
    { value: 'PermissionSet', text: 'PermissionSet' },
    { value: 'PermissionsChanged', text: 'PermissionsChanged' },
    { value: 'PermissionsDeleted', text: 'PermissionsDeleted' },
    { value: 'PolicyChangesDeleted', text: 'PolicyChangesDeleted' },
    { value: 'PolicyChangesDelivered', text: 'PolicyChangesDelivered' },
    { value: 'PolicyChangesRequested', text: 'PolicyChangesRequested' },
    { value: 'RefreshToken', text: 'RefreshToken' },
    { value: 'SMSContactVerification', text: 'SMSContactVerification' },
    { value: 'SMSTriggerVerification', text: 'SMSTriggerVerification' },
    { value: 'SoftDeleted', text: 'SoftDeleted' },
    { value: 'SuccessfulAuthenticationBiometrics', text: 'SuccessfulAuthenticationBiometrics' },
    { value: 'TriggerContactUpdateEMAIL', text: 'TriggerContactUpdateEMAIL' },
    { value: 'TriggerTrustLevelUpdate', text: 'TriggerTrustLevelUpdate' },
    { value: 'UWRCreate', text: 'UWRCreate' },
    { value: 'UWRImport-Zurich_OKTA_IE_GDP_SMETCORE_DEV_ADMIN', text: 'UWRImport-Zurich_OKTA_IE_GDP_SMETCORE_DEV_ADMIN' },
    { value: 'UWRImport-Zurich_OKTA_IE_GDP_SMETCORE_DEV_READONLY', text: 'UWRImport-Zurich_OKTA_IE_GDP_SMETCORE_DEV_READONLY' },
    { value: 'UWRImport-Zurich_OKTA_IE_GDP_SMETCORE_DEV_UWR', text: 'UWRImport-Zurich_OKTA_IE_GDP_SMETCORE_DEV_UWR' },
    { value: 'UWRUpdate', text: 'UWRUpdate' },
    { value: 'UWRUpsert', text: 'UWRUpsert' },
    { value: 'UpdateTrustLevelHigh', text: 'UpdateTrustLevelHigh' },
    { value: 'UpdateTrustLevelMedium', text: 'UpdateTrustLevelMedium'  },
    { value: 'Upload', text: 'Upload' },
    { value: 'UserAuthenticated', text: 'UserAuthenticated'  },
    { value: 'UserDeactivated', text: 'UserDeactivated' },
    { value: 'UserDeleted', text: 'UserDeleted' },
    { value: 'UserPatched', text: 'UserPatched'  },
    { value: 'UserReactivated', text: 'UserReactivated' },
    { value: 'UserRegistration', text: 'UserRegistration' }
];


export enum AuditLogQueryParameter {
    Login = 'login',
    ClientId = 'clientId'
}