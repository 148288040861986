import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './NavigationHeader.css';

interface NavigationHeaderProps {
  name: string;
}

const NavigationHeader: FC<NavigationHeaderProps> = (props: NavigationHeaderProps) => 
{  
  return (
  <div className="NavigationHeader">
    <nav className="header">
      <div className="header-navbar">
        <Link to="/" className="header-toggle"> 
          <span className="app-name mr-2">AMMI Dashboard</span>
          <span className="icon icon--menu_24 mr-3"></span>
        </Link>
        <div className="d-flex align-items-center ml-auto">
          <div className="avatar avatar--primary mr-2">
            <a href="/api/authentication/sso-login"><span className="icon icon--user-male_24_outline"></span></a>
          </div>
          <span className="mr-3">{props.name}</span>
        </div>
      </div>     
    </nav>
  </div>
)};

export default NavigationHeader;
