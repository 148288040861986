import axios, { AxiosRequestConfig } from "axios";
import { AuditLogApi, AuditLogPagedResponse, AuditLogSearchRequest, Configuration } from "../api/out-gdp-ammi-dashboard-backend-api";
import { GdpOptions } from "../config/GdpOptions";

export class AuditLogService {
    async fetchAuditLogs(request: AuditLogSearchRequest, signal?: AbortSignal) : Promise<AuditLogPagedResponse> {
        try {
            const options = GdpOptions;
            const configuration = new Configuration();
            configuration.basePath = options.backendUrl;
            const auditLogsApi = new AuditLogApi(configuration);

            const axiosRequestConfig: AxiosRequestConfig = {};
            if (signal) {
                axiosRequestConfig.signal = signal;
            }
                            
            const auditLogsResponse = await auditLogsApi.apiAuditLogSearchPost(request, axiosRequestConfig); 
            return auditLogsResponse.data;
        }
        catch (error) {
            console.error('Error fetching users:', error);
            throw error;
        }
    }
}