import { ClientAuthorizationApi, ClientAuthorization, Configuration } from '../api/out-gdp-ammi-dashboard-backend-api';
import {ClientAuthorizationUI, ClientAuthorizationUIFromApi} from '../contracts/ClientAuthorizationUI';
import { GdpOptions } from '../config/GdpOptions';

export class ClientAuthorizationsService{

  async fetchClientAuthorization() : Promise<ClientAuthorizationUI[]> {
    const options = GdpOptions;
    const configuration = new Configuration();
    configuration.basePath = options.backendUrl;
    const clientAuthorizationApi = new ClientAuthorizationApi(configuration);

    const clientAuthorizations = await clientAuthorizationApi.clientAuthorizationAuthorizationsGet();
    var dataForUI: ClientAuthorizationUI[] = [];
    clientAuthorizations.data.forEach(authorization => {
      authorization.allowedList?.forEach(api => {
        api.endpoints?.forEach(endpoint => {
          const authorizationRow = ClientAuthorizationUIFromApi(authorization, api, endpoint, true);
          dataForUI.push(authorizationRow); 
        });
      });

      authorization.excludedList?.forEach(api => {
        api.endpoints?.forEach(endpoint => {
          const authorizationRow = ClientAuthorizationUIFromApi(authorization, api, endpoint, false);
          dataForUI.push(authorizationRow); 
        });
      });

    })

    return dataForUI;
  }

}