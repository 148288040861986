import React, { FC, useState  } from 'react';
import { Link } from 'react-router-dom';
import { menuItems } from '../../constants/menuItems';
import './LeftSideMenu.css';

interface LeftSideMenuProps {
  onItemClick: (item: string) => void;
}

const LeftSideMenu: FC<LeftSideMenuProps> = ({ onItemClick }) => {
  const [expandedApis, setExpandedApis] = useState<boolean>(false);
  const [expandedClientAuth, setExpandedClientAuth] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>('');

  const toggleApisSubMenu = () => {
    setExpandedApis(!expandedApis);
  };

  const toggleClientAuthSubMenu = () => {
    setExpandedClientAuth(!expandedClientAuth);
  };

  const handleItemClick = (item: string) => {
    onItemClick(item);
    setSelectedItem(item);
  };

  const getNestedClass = (expanded: boolean) => {
    return expanded ? "nested expanded" : "nested";
  };

  const getExpandedClass = (expanded: boolean) => {
    return expanded ? "expanded" : "";
  };

  const getArrowClass = (expanded: boolean) => {
    return expanded ? "icon icon--arrow-up_24" : "icon icon--arrow-down_24";
  };

  const getActiveClass = (menuItem: string) => {
    return selectedItem === menuItem ? "active" : "";
  };

  return (
    <div className="left-side-menu">
      <ul className="menu-list">
        <li className="nested-level-1">
          <Link to={menuItems.API_HEALTH.url} 
            onClick={() => handleItemClick(menuItems.API_HEALTH.name)} 
            className={getActiveClass(menuItems.API_HEALTH.name)}>
            {menuItems.API_HEALTH.name}
            <span className="icon icon--heart-cardio_24_outline spanIcon"></span>
          </Link>
        </li>
        <li className="nested-level-1">
          <Link to="#" 
            className={getExpandedClass(expandedApis)} 
            onClick={toggleApisSubMenu}>
            {menuItems.GDP_APIS.name}
            <span className={getArrowClass(expandedApis)}></span>
          </Link>
          <ul className={getNestedClass(expandedApis)}>
            <li className="nested-level-2">
              <Link to="#" 
                onClick={toggleClientAuthSubMenu} 
                className={getExpandedClass(expandedClientAuth)}>
                {menuItems.CLIENT_AUTH.name}
                <span className={getArrowClass(expandedClientAuth)}></span>
              </Link>
              <ul className={getNestedClass(expandedClientAuth)}>
                <li className="nested-level-3">
                  <Link to={menuItems.VIEW_CLIENT_AUTH.url} 
                    onClick={() => handleItemClick(menuItems.VIEW_CLIENT_AUTH.name)} 
                    className={getActiveClass(menuItems.VIEW_CLIENT_AUTH.name)}>
                    {menuItems.VIEW_CLIENT_AUTH.name}
                  </Link>
                </li>
                <li className="nested-level-3">
                  <Link to={menuItems.UPDATE_CLIENT_AUTH.url} 
                    onClick={() => handleItemClick(menuItems.UPDATE_CLIENT_AUTH.name)} 
                    className={getActiveClass(menuItems.UPDATE_CLIENT_AUTH.name)}>
                    {menuItems.UPDATE_CLIENT_AUTH.name}
                  </Link>
                </li>
                <li className="nested-level-3">
                  <Link to={menuItems.REVOKE_CLIENT_AUTH.url} 
                    onClick={() => handleItemClick(menuItems.REVOKE_CLIENT_AUTH.name)} 
                    className={getActiveClass(menuItems.REVOKE_CLIENT_AUTH.name)}>
                    {menuItems.REVOKE_CLIENT_AUTH.name}
                  </Link>
                </li>
                <li className="nested-level-3">
                  <Link to={menuItems.HISTORY_LOG.url} 
                    onClick={() => handleItemClick(menuItems.HISTORY_LOG.name)} 
                    className={getActiveClass(menuItems.HISTORY_LOG.name)}>
                    {menuItems.HISTORY_LOG.name}
                  </Link>
                </li>                          
              </ul>
            </li>
            <li className="nested-level-2">
              <Link to={menuItems.AUDIT_LOG.url} 
                onClick={() => handleItemClick(menuItems.AUDIT_LOG.name)} 
                className={getActiveClass(menuItems.AUDIT_LOG.name)}>
                {menuItems.AUDIT_LOG.name}
              </Link>
            </li>
            <li className="nested-level-2">
              <Link to={menuItems.USERS.url} 
                onClick={() => handleItemClick(menuItems.USERS.name)} 
                className={getActiveClass(menuItems.USERS.name)}>
                {menuItems.USERS.name}
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default LeftSideMenu;
