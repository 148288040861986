export const businessUnitOptions = [
    { value: 'ZG_ZI_Zims_Zurich_Insurance_Mobile_Solutions', label: 'ZG_ZI_Zims_Zurich_Insurance_Mobile_Solutions' },
    { value: 'EC_GL_Zurich_Ecuador', label: 'EC_GL_Zurich_Ecuador' },
    { value: 'ZG_GL_Group', label: 'ZG_GL_Group' },
    { value: 'IT_CN_ZIC_Italy_Connect', label: 'IT_CN_ZIC_Italy_Connect' },
    { value: 'IT_GL_Zurich_Italia', label: 'IT_GL_Zurich_Italia' },
    { value: 'ZG_LW_LiveWell', label: 'ZG_LW_LiveWell' },
    { value: 'ZG_ME_Zurich_Middle_East', label: 'ZG_ME_Zurich_Middle_East' },
    { value: 'MX_GL_Zurich_Mexico', label: 'MX_GL_Zurich_Mexico' },
    { value: 'ID_GL_Zurich_Indonesia', label: 'ID_GL_Zurich_Indonesia' },
    { value: 'CO_GL_Zurich_Colombia', label: 'CO_GL_Zurich_Colombia' },
    { value: 'ZG_IM_Zurich_International', label: 'ZG_IM_Zurich_International' },
    { value: 'IE_GI_Zurich_Ireland', label: 'IE_GI_Zurich_Ireland' },
    { value: 'DE_GI_Zurich_Deutschland', label: 'DE_GI_Zurich_Deutschland' },
    { value: 'BR_GI_Zurich_Brasil', label: 'BR_GI_Zurich_Brasil' },
    { value: 'DE_DA_DADirekt', label: 'DE_DA_DADirekt' },
    { value: 'CH_GL_Zurich_Schweiz', label: 'CH_GL_Zurich_Schweiz' },
    { value: 'PT_LF_Zurich_Portugal', label: 'PT_LF_Zurich_Portugal' },
    { value: 'C4E_Mock', label: 'C4E_Mock' },
    { value: 'Wildcarded_Business_Unit', label: 'Wildcarded_Business_Unit' },
    { value: 'ZG_LP_Zurich_Corporate_Life_and_Pensions', label: 'ZG_LP_Zurich_Corporate_Life_and_Pensions' },
    { value: 'ES_GI_Zurich_Spain', label: 'ES_GI_Zurich_Spain' }
];