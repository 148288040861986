import React, { useEffect, useState } from 'react';
import './ViewClientAuthorizations.css';
import ClientAuthorizationsTable from '../ClientAuthorizationsTable/ClientAuthorizationsTable';
import { ClientAuthorizationsService } from '../../services/ClientAuthorizationsService';
import {ClientAuthorizationUI} from '../../contracts/ClientAuthorizationUI';

function ViewClientAuthorizations() {
  const [clientData, setClientData] = useState<ClientAuthorizationUI[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const clietnAuthorizationApi = new ClientAuthorizationsService();

  useEffect(() => {
    const fetchAuthorizations = async () => {
      try {
        const data = await clietnAuthorizationApi.fetchClientAuthorization();
        setClientData(data);
      } 
      catch (error) {
        const err = error as Error;
        setError(err);
      } finally {
        setLoading(false);
      }
      
    }
    fetchAuthorizations(); 
  }, []);

  if (loading) {
    return (
      <h1>Loading</h1>
    )
  } else {
    if (error !== null) {
      return (
        <span>Error: {error.message}</span>
      )
    }
    return (
      <div className="ViewClientAuthorizations">
        <ClientAuthorizationsTable clientData={clientData} ></ClientAuthorizationsTable>
      </div>
    );
  }
 
};

export default ViewClientAuthorizations;
